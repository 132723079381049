import React, { useState } from 'react';
import { Button, makeStyles, Grid } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet, usePDF } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import EmailModal from '../PDFComponents/EmailModal';
import PDFHeader from '../PDFComponents/PDFHeader';
import PDFInvoiceInfo from '../PDFComponents/PDFInvoiceInfo';
import PDFSummary from '../PDFComponents/PDFSummary';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    tableText: {
        fontSize: 10,
        marginBottom: '1px',
        textAlign: 'center',
    },
    tableTextBold: {
        fontSize: 10,
        marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginTop: '5px',
        textAlign: 'center',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
        textAlign: 'center',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    instructionHeader: {
        paddingBottom: '5px',
        fontSize: 12,
        color: 'blue',
    },
});

const SortByDatePDF = (props) => {

    const classes = useStyles();
    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
    */

    const [ticketData] = useState(props.ticketData)
    const [invoiceDetails] = useState(props.invoiceDetails)
    const [customer] = useState(props.customers.pop())
    const [equipment] = useState(props.equipment)
    //const [customerJobs] = useState(props.customerJobs)
    //const [rates] = useState(props.rates)
    const [columns] = useState(props.columns)
    const [modalOpen, setModalOpen] = useState(false)

    const formattedTickets = props.ticketData.reduce(
        (accumulator, ticket) => {
            let formattedDate = moment(ticket.date).format("dddd, MMMM D, YYYY")
            return {...accumulator, [formattedDate]: [...accumulator[formattedDate] || [], ticket]} 
        },[],
    );

    const tableContents = (
        <View>
            {/* thead */}
            <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: 'row'}}>
                    {columns.map((column, columnIndex) => (
                        <Text key={columnIndex} style={{...styles.tableTextBoldUnderline, width: `${column.width}%`}}>
                            {column.header}
                        </Text>
                    ))}
                </View>
            </View>

            {/* tbody */}
            <View>
                {Object.entries(formattedTickets).map(([rateKey, rateValue], rateIndex) => (
                    <View key={rateIndex}>
                        <Text style={{...styles.instructionHeader}}>{`${rateKey || ''}`.split('')}</Text>
                        {rateValue.map((ticket, ticketIndex) => (
                            <View key={ticketIndex} style={ticketIndex % 2 === 0 ? {flexDirection: 'row', backgroundColor: 'grey'} : {flexDirection: 'row',}}>
                                {columns.map((column, columnIndex) => {
                                        switch(column.field) {
                                            case 'date':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {moment(ticket.date).format('DD-MMM-YYYY')} 
                                                        </Text>
                                            case 'unitNumber':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {ticket.units.map((unit) => { return ( equipment?.find(x=> x?.docId === unit)?.unitNumber ) })} 
                                                        </Text>                        
                                            case 'ticket':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {ticket.ticket} 
                                                        </Text>
                                            case 'material':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {ticket.material} 
                                                        </Text>
                                            case 'materialUnits':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {Number(ticket.materialUnits || 0).toFixed(3)} 
                                                        </Text>                                    
                                            case 'materialSalesRate':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}>  
                                                            {`$${Number(ticket.materialSalesRate || 0).toFixed(2)}`} 
                                                        </Text>
                                            case 'materialSalesTotal':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}>  
                                                            {`$${Number(ticket.materialSalesTotal || 0).toFixed(2)}`} 
                                                        </Text>                                    
                                            case 'cartageSaleUnitsWPer':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {`${Number(ticket.cartageSaleUnits || 0).toFixed(3)} ${ticket.cartageSalesPer || ''}`} 
                                                        </Text>
                                            case 'cartageSaleUnits':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {Number(ticket.cartageSaleUnits || 0).toFixed(3)} 
                                                        </Text>
                                            case 'cartageSaleRate':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {`$${Number(ticket.cartageSaleRate || 0).toFixed(2)}`} 
                                                        </Text>
                                            case 'cartageSalesTotal':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {`$${Number(ticket.cartageSalesTotal || 0).toFixed(2)}`} 
                                                        </Text>
                                            case 'rateTotal':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                            {`$${Number(Number(ticket.cartageSaleRate || 0) + Number(ticket.materialSalesRate || 0)).toFixed(2)}`} 
                                                        </Text>  
                                            case 'total':
                                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}>  
                                                            {`$${Number(Number(ticket.materialSalesTotal || 0) + Number(ticket.cartageSalesTotal || 0)).toFixed(2)}`} 
                                                        </Text>       
                                            default:
                                                console.warn('Field not available within render switch.')  
                                                return null;
                                        }
                                })}
                            </View>
                        ))}
                    </View>
                ))}
                {/* totals */}
                <View style={{flexDirection: 'row'}}>
                    {columns.map((column, columnIndex) => {
                        switch(column.field) {
                            case 'date':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />
                            case 'unitNumber':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />       
                            case 'ticket':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />
                            case 'material':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />
                            case 'materialUnits':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}> 
                                            {ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.materialUnits || 0)).toFixed(3), 0,)} 
                                        </Text>                                    
                            case 'materialSalesRate':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex} />
                            case 'materialSalesTotal':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}> 
                                            {`$${ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.materialSalesTotal || 0)).toFixed(2), 0,)}`} 
                                        </Text>                                    
                            case 'cartageSaleUnitsWPer':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}> 
                                            {ticketData.reduce((accumulator, currentValue) => Number(Number(accumulator) + Number(currentValue.cartageSaleUnits || 0)).toFixed(3), 0,)} 
                                        </Text>
                            case 'cartageSaleUnits':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}> 
                                            {ticketData.reduce((accumulator, currentValue) => Number(Number(accumulator) + Number(currentValue.cartageSaleUnits || 0)).toFixed(3), 0,)} 
                                        </Text>
                            case 'cartageSaleRate':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex} />
                            case 'cartageSalesTotal':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}>
                                            {`$${ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.cartageSalesTotal || 0)).toFixed(2), 0,)}`} 
                                        </Text>
                            case 'rateTotal':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex} />
                            case 'total':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}>
                                            {`$${ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + (Number(currentValue.materialSalesTotal || 0) + Number(currentValue.cartageSalesTotal || 0))).toFixed(2), 0,)}`}
                                        </Text>     
                            default:
                                console.warn('Field not available within render switch.') 
                                return null; 
                        }
                    })}
                </View>
            </View>
        </View>
    )

    const myDoc = (
        <Document>
            <Page size="A4" style={styles.page} wrap orientation={invoiceDetails.combinedMaterialCartageSales  ? 'portrait' : 'landscape'}> 

                <PDFHeader 
                    //invoiceDetails={invoiceDetails}
                />

                <PDFInvoiceInfo 
                    customer={customer}
                    invoiceDetails={invoiceDetails}
                    ticketData={ticketData}
                />

                {tableContents}

                <PDFSummary 
                    ticketData={ticketData}
                    invoiceDetails={invoiceDetails}
                />

            </Page>
        </Document>
    )

    const [instance] = usePDF({ document: myDoc });

    return (
        <div className={classes.root}>

            <EmailModal 
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                email={customer.email}
                handleSendEmail={() => {
                     props.handleEmailInvoice(instance, `${moment(ticketData.map((ticket) => { return ticket.date}).reduce(function (a, b) { return a < b ? a : b})).format('MMMM D, YYYY')} to ${moment(invoiceDetails.invoiceDate).format('MMMM D, YYYY')}`, customer.name, customer.email) 
                }}
            />

            <div className={classes.content}>

                <Grid container className={classes.buttonRow}>
                    <Grid item xs={6}>
                        <Button 
                            variant='contained'
                            onClick={()=> { props.history.goBack() }}
                            startIcon={<ArrowBackIcon />}
                        >
                                Back
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent='flex-end'>
                            <Button 
                                variant='contained'
                                disabled={!customer.email}
                                onClick={()=> {  setModalOpen(true) }}
                            >
                                    Email Customer Invoice
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <PDFViewer style={styles.viewer}>
                    {myDoc}
                </PDFViewer>
            </div>
        </div>
    )
};

export default withRouter(SortByDatePDF);