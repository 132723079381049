import React from 'react';
import {Button, Divider, Grid, IconButton, Paper, TextField, Typography, makeStyles, Select, MenuItem} from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    btnGroup:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    card: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
    },
    content: {
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 'auto',
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
    paper: {
        width:'100%',
        maxWidth: '500px',
        padding: theme.spacing(2),
    },
    row:{
        display: 'flex',
        alignItems:'center',
    },
    title: {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '32px',
    },
    text: {
        color: '#707070',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const HRForm = (props) => {
    const classes = useStyles();

    return (
        <Grid className={classes.content}>
            <Grid className={classes.backButton}>
                <Button variant="contained" startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.goBack()} >Back</Button>
            </Grid>
            <form onSubmit={props.handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} className={classes.title}>
                            <MenuBookIcon style={{marginRight:'8px'}}/>
                            <Typography variant="h5" align="center">New {props.title} Document</Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Document Name*</Typography>
                            <TextField required size="small" fullWidth variant='outlined' name="name" onChange={props.handleChange} value={props.hrForm.name}/>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography>Description*</Typography>
                            <TextField component="pre" required multiline rows={3} size="small" fullWidth variant='outlined' name="description" onChange={props.handleChange} value={props.hrForm.description}/>
                        </Grid>

                        <Grid item sm={12}>
                            <Typography>Document Type</Typography>
                            <Select
                                value={props.hrForm.docType}
                                onChange={props.handleChange}
                                name="docType"
                                fullWidth
                                variant='outlined'
                                size="small"
                            >
                                {props.docTypes.map((doc, index) => {
                                    return (
                                        <MenuItem key={index} value={doc.value}>{doc.display}</MenuItem>
                                    )
                                })}

                            </Select>
                        </Grid>

                        <Grid item sm={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sm={12}>
                            <Divider/>
                        </Grid>

                        <Grid item sm={12} className={props.hrPDF?.name ? classes.fileInputAccept : classes.fileInput}>
                            <Dropzone accept={{'application/pdf': ['.pdf', '.PDF']}} onDrop={props.handleFileUpload}>
                                {({ getRootProps, getInputProps }) => (
                                    <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                        <input {...getInputProps()} style={{ display: 'none' }} />
                
                                        <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                        <Typography variant='h6'>
                                            {props.hrPDF?.name ? props.substringText(props.hrPDF?.name) : "Upload Document (.pdf)"}
                                        </Typography>
                                    </Grid>
                                )}
                            </Dropzone>

                            {props.hrPDF?.name ?
                                <IconButton component='span' style={{ marginLeft: '100px', color:'black' }} onClick={() => {props.setMsdsPDF()}}>
                                    <ClearIcon />
                                </IconButton>
                            : null}
                        </Grid>

                        <Grid item sm={12}>
                            <Typography type='url' align="center" style={{color:'red'}}>{props.error}</Typography>
                        </Grid>

                        <Grid item sm={12}>
                            <Button 
                                fullWidth 
                                variant="contained" 
                                size="large" 
                                disabled={props.submitting === 1 || props.submitting === -1} 
                                type="submit"
                                style={props.submitting === -1 ? {backgroundColor: 'green', color: 'white'} : null}
                            >
                                {props.submitting === 1 ? "Submitting..." : props.submitting === -1 ? "Submitted!" : `Create ${props.title} Document`}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Grid>
    )
}

export default withRouter(HRForm);