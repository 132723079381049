import React from 'react';
import { Button, Grid,  Paper, Modal, Backdrop, Fade, Typography, } from '@material-ui/core';
//import _ from 'lodash';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const EmailModal = ({ modalOpen, setModalOpen, handleSendEmail, email }) => {

    return (
        <>
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={modalOpen}
                onClose={() => { setModalOpen(false) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={modalOpen}>
                    <Paper style={{ padding: '16px', maxWidth:'700px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography align='center'>
                                    This will send an invoice to {email},
                                    Are you sure?
                                </Typography>
                            </Grid>

                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        startIcon={<CloseIcon/>} 
                                        style={{color:'white',backgroundColor:'red', marginRight: '10px'}}
                                        variant='contained'
                                        onClick={(e) => { setModalOpen(false) }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        startIcon={<CheckIcon/>}
                                        variant='contained'
                                        onClick={(e) => {
                                            handleSendEmail()
                                            setModalOpen(null)
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
        </>

    )
}

export default (EmailModal)