import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';
import errorReporter from "../utility/errorReporter";
import ErrorFallBack from "./ErrorFallBack/ErrorFallBack";

import Checklist from './Reporting/ChecklistReport/Checklist';
import Customer from './Customers/Customer';
import CustomerHome from './Customers/CustomerHome';
import CustomerNew from './Customers/CustomerNew';
import CustomerReport from './Customers/CustomerReport'
import CustomerTablePrint from '../containers/Customers/CustomerTablePrint';
import CustomerRates from "./Customers/CustomerRates";
import Defect from './Reporting/DefectReports/DefectReports';
import Covid from './Reporting/CovidReport/Covid';
import Delivery from './Reporting/DeliveryReport/Delivery';
import Drawer from '../components/Navigation/SideDrawer/SideDrawer';
import Equipment from './EquipmentInventory/Equipment';
import EquipmentHome from './EquipmentInventory/EquipmentHome';
import EquipmentNew from './EquipmentInventory/EquipmentNew';
import EquipmentReport from './EquipmentInventory/EquipmentReport';
import EquipmentPrint from './EquipmentInventory/EquipmentPrint';
import Form from './Forms/Form'
import FormHome from './Forms/FormHome';
import FormNew from './Forms/FormNew';
import FormReport from './Forms/FormReport';
import Home from './Home/Home';
import Job from './Reporting/JobReport/Job';
import Modal from '../components/UI/Modal/Modal';
import Order from '../containers/Shop/Order/Order';
import OrderNew from "./Shop/Order/OrderNew";
import Orders from '../containers/Shop/Orders/Orders';
import Product from './ProductInventory/Product';
import ProductHome from './ProductInventory/ProductHome';
import Coupon from './Coupons/Coupon';
import CouponHome from './Coupons/CouponHome';
import Reporting from '../containers/Reporting/Reporting';
import Safety from './Safety/Safety';
import SafetyReport from './Safety/SafetyReport'
import SafetyHome from './Safety/SafetyHome';
import SafetyNew from './Safety/SafetyNew';
import SignIn from '../components/SignIn/SignIn';
import Timesheet from '../containers/Reporting/TimesheetReport/Timesheet';
import TimesheetPrint from '../containers/Reporting/TimesheetReport/TimesheetPrint';
import TimesheetSelectedPrint from "./Reporting/TimesheetReport/TimesheetSelectedPrint";
import UnitPrint from "./Reporting/UnitReport/UnitPrint";
import TimesheetTablePrint from '../containers/Reporting/TimesheetReport/TimesheetTablePrint';
import TimesheetTotalPrint from "./Reporting/TimesheetReport/TimesheetTotalPrint";
import JobTablePrint from '../containers/Reporting/JobReport/JobTablePrint';
import ChecklistTablePrint from "./Reporting/ChecklistReport/ChecklistTablePrint";
import ChecklistAllPrint from "./Reporting/ChecklistReport/ChecklistAllPrint";
import OrderPrint from '../containers/Reporting/OrderReport/OrderPrint';
import CustomerPrint from '../containers/Reporting/CustomerReport/CustomerPrint';
import Unit from './Reporting/UnitReport/Unit';
import User from './Users/User';
import UserNew from './Users/UserNew';
import UserHome from './Users/UserHome';
import UserReport from './Users/UserReport';
import UserRates from "./Users/UserRates";
import PDFHome from "./PDFs/PDFHome";
import TechHome from "./Tech/TechHome";
import PDFNew from "./PDFs/PDFNew";
import WorkOrdersHome from "./WorkOrders/WorkOrdersHome";
import WorkOrderNew from "./WorkOrders/WorkOrderNew";
import PDF from "./PDFs/PDF";
import NewDocReport from "../components/PDFs/NewDocReport";
import ResumeHome from './Resume/ResumeHome';
import ChangelogHome from './Changelog/ChangelogHome';
import Resume from './Resume/Resume';
import CategoryHome from "./Categories/CategoryHome";
import ApprovalHome from "./Approval/Approval";
import RolesHome from "./Users/Roles/RolesHome";
import Role from "./Users/Roles/Role";
import Photos from './Reporting/PhotosReport/Photos';
import Document from './Reporting/DocumentsReport/Document';
import ChecklistHome from './Checklists/ChecklistHome';
import Checklist2 from './Checklists/Checklist';
import ChecklistPrint from "./Checklists/ChecklistPrint";
import WorkOrderPrint from "./WorkOrders/WorkOrderPrint";
import ChecklistReportPrint from "./Reporting/ChecklistReport/ChecklistReportPrint";
import { useAuth } from '../../providers/AuthContext';
import { CustomerProvider } from '../../providers/CustomerContext';
import { ProductProvider } from '../../providers/ProductContext';
import { SettingsProvider } from '../../providers/SettingsProvider';
import { CouponProvider } from '../../providers/CouponsProvider';
import { UserProvider } from '../../providers/UserContext';
import { TimesheetTypeProvider } from '../../providers/TimesheetTypeContext';
import Meta from '../../providers/Meta';
import { UnitProvider } from '../../providers/UnitContext';
import { UnitDescriptionProvider } from "../../providers/UnitDescriptionContext";
import Conversations from "./Shop/Order/Conversations";

import { makeStyles, StylesProvider } from '@material-ui/core'
import _ from "lodash";
//import Developer from "./Developer/Developer";
import ProductMetaTags from "./ProductInventory/ProductMetaTags";
import SalesEquipmentHome from "./EquipmentInventory/SalesEquipment/SalesEquipmentHome";
import { SalesEquipmentProvider } from "../../providers/SalesEquipmentContext";
import ShopSettings from "./Shop/ShopSettings/ShopSettings";
import BusinessSettings from "./Shop/ShopSettings/BusinessSettings";
import ZoneSettings from "./Shop/ShopSettings/ZoneSettings";
import UserAsbtractAgreementPrint from "./Reporting/UserReport/UserAsbtractAgreementPrint";
import UserAsbtractPermissionPrint from "./Reporting/UserReport/UserAbstractPermissionPrint";
import UserPrint from "./Reporting/UserReport/UserPrint";
import { FormProvider } from "../../providers/DocumentContext";
import Scheduler from "./Scheduler/Scheduler";
import MSDS from "./MSDS/MSDS";
import MSDSHome from "./MSDS/MSDSHome";
import MSDSNew from "./MSDS/MSDSNew";
import HR from "./HumanResources/HR";
import HRHome from "./HumanResources/HRHome";
import HRNew from "./HumanResources/HRNew";
import Permits from "./Permits/Permits";
import PermitsHome from "./Permits/PermitsHome";
import PermitsNew from "./Permits/PermitsNew";
import OrderSelectedPrint from "./Reporting/OrderReport/OrderSelectedPrint";
import WorkOrder from "./WorkOrders/WorkOrder";
import GiftCardsHome from "./GiftCards/GiftCardsHome";
import GiftCardsNew from "./GiftCards/GiftCardsNew";
import GiftCard from "./GiftCards/GiftCard";
import GrainOrders from "./Grain/GrainOrders";
import GrainOrdersHome from "./Grain/GrainOrdersHome";
import GrainOrdersNew from "./Grain/GrainOrdersNew";
import GrainPrint from "./Grain/GrainPrint";
import BugReportsHome from "./BugReports/BugReportsHome";
import UsersPrint from "./Users/UsersPrint";
import Ticket from "./Ticket/Ticket";
import Invoice from "./Invoice/Invoice";
import InvoicePrint from "./Invoice/InvoicePrint";
import Payroll from "./Payroll/Payroll";
import PayrollPrint from "./Payroll/PayrollPrint";
import QuotesHome from "./Quotes/QuotesHome";
import RatesHome from "./Rates/RatesHome";
import Rates from "./Rates/Rates";
import EmployeeMemo from "./Users/Memos/EmployeeMemo";
import OrderNewSquare from "./Shop/Order/OrderNewSquare";

//import DefectReports from "./Reporting/DefectReports/DefectReports";

// function Application() {
  const Application = (props) => {

    const { currentUser, loading } = useAuth();

    const useStyles = makeStyles((theme) => ({
      container: {
        width: '100%',
      },
    }))

    const classes = useStyles();

    const location = useLocation();

    React.useEffect(() => {
    }, [props.colorMode]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      !loading && <div className={classes.container}>
        {!_.isEmpty(currentUser) && currentUser !== null
          ? <Drawer colorMode={props.colorMode} setColorMode={props.setColorMode}>
            
            <StylesProvider>
              <SalesEquipmentProvider>
                <CustomerProvider>
                  <ProductProvider>
                    <SettingsProvider>
                      <CouponProvider>
                        <UserProvider>
                          <FormProvider>
                            <UnitProvider>
                              <UnitDescriptionProvider>
                                <TimesheetTypeProvider>
                                <Meta title={'Gee Tee Dashboard'}/>

                                  <ErrorBoundary fallback={<ErrorFallBack/>} onError={(error) => {errorReporter(error, location)}} key={location.pathname}>
                                    <Switch>
                                      
                                      <Route path="/dashboard" exact> <Home /></Route>
                                      <Route path="/dashboard/customers" exact> <CustomerHome /></Route>
                                      <Route path="/dashboard/customers/form" exact> <Customer /></Route>
                                      <Route path="/dashboard/customers/new" exact> <CustomerNew /></Route>
                                      <Route path="/dashboard/customers/reporting" exact> <CustomerReport /></Route>
                                      <Route path="/dashboard/customers/printing/table" exact> <CustomerTablePrint /></Route>
                                      <Route path="/dashboard/customers/rates" exact> <CustomerRates /></Route>
                                      <Route path="/dashboard/products" exact> <ProductHome /></Route>
                                      <Route path="/dashboard/coupons" exact> <CouponHome /></Route>
                                      <Route path="/dashboard/products/metatags" exact> <ProductMetaTags /></Route>
                                      <Route path="/dashboard/products/product"> <Product /></Route>
                                      <Route path="/dashboard/coupons/coupon"> <Coupon /></Route>
                                      <Route path="/dashboard/reporting" exact> <Reporting  colorMode={props.colorMode} setColorMode={props.setColorMode} /></Route>
                                      <Route path="/dashboard/equipment" exact> <EquipmentHome /></Route>
                                      <Route path="/dashboard/equipment/new" exact> <EquipmentNew /></Route>
                                      <Route path="/dashboard/equipment/form" exact> <Equipment /></Route>
                                      <Route path="/dashboard/equipment/reporting" exact> <EquipmentReport /></Route>
                                      <Route path="/dashboard/forms" exact> <FormHome /></Route>
                                      <Route path="/dashboard/forms/form" exact> <Form /></Route>
                                      <Route path="/dashboard/forms/new" exact> <FormNew /></Route>
                                      <Route path="/dashboard/forms/reporting" exact> <FormReport /></Route>
                                      <Route path="/dashboard/reporting/timesheets" exact> <Timesheet /></Route>
                                      <Route path="/dashboard/reporting/timesheets/checklist" exact> <Checklist /></Route>
                                      <Route path="/dashboard/reporting/timesheets/defect" exact> <Defect /></Route>
                                      <Route path="/dashboard/reporting/timesheets/delivery" exact> <Delivery /></Route>
                                      <Route path="/dashboard/reporting/timesheets/job" exact> <Job /></Route>
                                      <Route path="/dashboard/reporting/timesheets/printing" exact> <TimesheetPrint /></Route>
                                      <Route path="/dashboard/reporting/timesheets/printing/table" exact> <TimesheetTablePrint /></Route>
                                      <Route path="/dashboard/reporting/timesheets/printing/total" exact> <TimesheetTotalPrint /></Route>
                                      <Route path="/dashboard/reporting/timesheets/printing/selected" exact> <TimesheetSelectedPrint /></Route>
                                      <Route path="/dashboard/reporting/jobs/printing/table" exact> <JobTablePrint /></Route>
                                      <Route path="/dashboard/reporting/checklists/printing/table" exact> <ChecklistTablePrint /></Route>
                                      <Route path="/dashboard/reporting/checklists/printing/all" exact> <ChecklistAllPrint /></Route>
                                      <Route path="/dashboard/reporting/orders/printing/selected" exact> <OrderSelectedPrint /></Route>
                                      <Route path="/dashboard/reporting/orders/printing" exact> <OrderPrint /></Route>
                                      <Route path="/dashboard/reporting/covidProtocol" exact> <Covid /></Route>
                                      <Route path="/dashboard/safety" exact> <SafetyHome /></Route>
                                      <Route path="/dashboard/safety/new" exact> <SafetyNew /></Route>
                                      <Route path="/dashboard/safety/form" exact> <Safety /></Route>
                                      <Route path="/dashboard/safety/reporting" exact> <SafetyReport /></Route>
                                      <Route path="/dashboard/msds" exact> <MSDSHome /></Route>
                                      <Route path="/dashboard/msds/new" exact> <MSDSNew /></Route>
                                      <Route path="/dashboard/msds/form" exact> <MSDS /></Route> 

                                      <Route path="/dashboard/hr/general/all" exact> <HRHome /></Route>
                                      <Route path="/dashboard/hr/general/policies" exact> <HRHome /></Route>
                                      <Route path="/dashboard/hr/general/accessibility" exact> <HRHome /></Route>

                                      <Route path="/dashboard/hr/healthsafety/all" exact> <HRHome /></Route>
                                      <Route path="/dashboard/hr/healthsafety/swp" exact> <HRHome /></Route>
                                      <Route path="/dashboard/hr/healthsafety/safetypolicies" exact> <HRHome /></Route>

                                      <Route path="/dashboard/hr/healthsafety/new" exact> <HRNew /></Route>
                                      <Route path="/dashboard/hr/general/new" exact> <HRNew /></Route>

                                      <Route path="/dashboard/hr/form" exact> <HR /></Route>

                                      <Route path="/dashboard/permits" exact> <PermitsHome /></Route>
                                      <Route path="/dashboard/permits/new" exact> <PermitsNew /></Route>
                                      <Route path="/dashboard/permits/form" exact> <Permits /></Route> 
                                      <Route path="/dashboard/pdfforms" exact> <PDFHome /></Route>
                                      <Route path="/dashboard/pdfforms/new" exact> <PDFNew /></Route>
                                      <Route path="/dashboard/pdfforms/form" exact> <PDF /></Route>
                                      <Route path="/dashboard/pdfforms/reporting" exact> <SafetyReport /></Route>
                                      <Route path="/dashboard/pdfforms/newdocreport" exact> <NewDocReport /></Route>
                                      <Route path="/dashboard/store/conversations" exact> <Conversations /></Route>
                                      <Route path="/dashboard/store/equipment" exact> <SalesEquipmentHome /></Route>
                                      <Route path="/dashboard/store/orders" exact> <Orders /></Route>
                                      <Route path="/dashboard/store/orders/new" exact> <OrderNew /></Route>
                                      {/* TEMP */}
                                      <Route path="/dashboard/store/orders/newSquare" exact> <OrderNewSquare /></Route>
                                      
                                      <Route path="/dashboard/store/orders/order"><Order /></Route>
                                      <Route path="/dashboard/store/shopSettings" exact> <ShopSettings /></Route>
                                      <Route path="/dashboard/store/businessSettings" exact> <BusinessSettings /></Route>
                                      <Route path="/dashboard/store/zoneSettings" exact> <ZoneSettings /></Route>
                                      <Route path="/dashboard/reporting/timesheets/unit" exact> <Unit /></Route>
                                      <Route path="/dashboard/reporting/documents" exact> <Document /></Route>
                                      <Route path="/dashboard/users/rates" exact> <UserRates /></Route>
                                      <Route path="/dashboard/users/new" exact> <UserNew /></Route>
                                      <Route path="/dashboard/users/reporting" exact> <UserReport /></Route>
                                      <Route path="/dashboard/users/form" exact> <User /></Route>
                                      <Route path="/dashboard/users/memos" exact> <EmployeeMemo /></Route>
                                      <Route path="/dashboard/users" exact> <UserHome /></Route>
                                      <Route path="/dashboard/resumes" exact> <ResumeHome /></Route>
                                      <Route path="/dashboard/resumes/resume" exact> <Resume /></Route>
                                      <Route path="/dashboard/users/roles" exact> <RolesHome /></Route>
                                      <Route path="/dashboard/users/roles/role" exact> <Role /></Route>
                                      <Route path="/dashboard/users/roles/role" exact> <Role /></Route> 
                                      <Route path="/dashboard/users/printing" exact> <UsersPrint /></Route> 
                                      <Route path="/dashboard/reporting/timesheets/photos" exact> <Photos /></Route>
                                      <Route path="/dashboard/changelog/logs" exact> <ChangelogHome /></Route>
                                      <Route path="/dashboard/equipment/printing" exact> <EquipmentPrint /></Route>
                                      <Route path="/dashboard/approval/timesheets" exact> <ApprovalHome /></Route>
                                      <Route path="/dashboard/customers/reporting/printing" exact> <CustomerPrint /></Route>
                                      <Route path="/dashboard/products/categories" exact> <CategoryHome /></Route>
                                      <Route path="/dashboard/checklists" exact> <ChecklistHome /></Route>
                                      <Route path="/dashboard/checklists/checklist" exact> <Checklist2 /></Route>
                                      <Route path="/dashboard/checklists/printing" exact> <ChecklistPrint /></Route>
                                      <Route path="/dashboard/reporting/timesheets/checklist/printing" exact> <ChecklistReportPrint /></Route>

                                      <Route path="/dashboard/giftCards/" exact> <GiftCardsHome /></Route>
                                      <Route path="/dashboard/giftCards/new" exact> <GiftCardsNew /></Route>
                                      <Route path="/dashboard/giftCards/form" exact> <GiftCard /></Route>
        
                                      <Route path="/dashboard/users/reporting/printing" exact> <UserPrint /></Route>
                                      <Route path="/dashboard/users/reporting/printing/abstract" exact> <UserAsbtractAgreementPrint /></Route>
                                      <Route path="/dashboard/users/reporting/printing/abstractpermission" exact> <UserAsbtractPermissionPrint /></Route>
                                      <Route path="/dashboard/reporting/units/printing" exact> <UnitPrint /></Route>

                                      <Route path="/dashboard/scheduler/view" exact> <Scheduler /></Route>
                                      <Route path="/dashboard/workorders" exact> <WorkOrdersHome /></Route>
                                      <Route path="/dashboard/workorders/new" exact> <WorkOrderNew /></Route>
                                      <Route path="/dashboard/workorders/form" exact> <WorkOrder /></Route>
                                      <Route path="/dashboard/workorders/printing" exact> <WorkOrderPrint /></Route>

                                      <Route path="/dashboard/grains/deck" exact> <GrainOrdersHome /></Route>
                                      <Route path="/dashboard/grains/geetee" exact> <GrainOrdersHome /></Route>
                                      <Route path="/dashboard/grains/wtl" exact> <GrainOrdersHome /></Route>
                                      <Route path="/dashboard/grains/all" exact> <GrainOrdersHome /></Route>

                                      <Route path="/dashboard/grains/new" exact> <GrainOrdersNew /></Route>
                                      <Route path="/dashboard/grains/form" exact> <GrainOrders /></Route>
                                      <Route path="/dashboard/grains/printing" exact> <GrainPrint /></Route>

                                      <Route path="/dashboard/tech/bugreports" exact> <BugReportsHome /></Route>

                                      <Route path="/dashboard/tickets" exact> <Ticket /></Route>
                                      
                                      <Route path="/dashboard/invoicing" exact> <Invoice /></Route>
                                      <Route path="/dashboard/invoicing/printing" exact> <InvoicePrint /></Route>

                                      <Route path="/dashboard/payroll" exact> <Payroll /></Route>
                                      <Route path="/dashboard/payroll/printing" exact> <PayrollPrint /></Route>

                                      <Route path="/dashboard/tech" exact> <TechHome /></Route>

                                      <Route path="/dashboard/quotes" exact> <QuotesHome /></Route>

                                      <Route path="/dashboard/rates" exact> <RatesHome /></Route>
                                      <Route path="/dashboard/rates/form" exact> <Rates /></Route> 



                                    </Switch>
                                  </ErrorBoundary>

                                </TimesheetTypeProvider>
                              </UnitDescriptionProvider>
                            </UnitProvider>
                          </FormProvider>
                        </UserProvider>
                      </CouponProvider>
                    </SettingsProvider>
                  </ProductProvider>
                </CustomerProvider>
              </SalesEquipmentProvider>
            </StylesProvider>
          </Drawer>
          : 
          <Modal show={!currentUser} >
            <SignIn />
          </Modal>}
      </div>
    );
  }

export default Application;
