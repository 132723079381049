import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const GrainReportDrawer = (props) => {

    const classes = useStyles();

    return <List>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/grains/deck'} button>
            <ListItemText primary={'Deck/Equipment'} />
        </ListItem>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/grains/geetee'} button>
            <ListItemText primary={'GeeTee Orders'} />
        </ListItem>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/grains/wtl'} button>
            <ListItemText primary={'WTL Orders'} />
        </ListItem>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/grains/all'} button>
            <ListItemText primary={'All'} />
        </ListItem>

    </List>
}