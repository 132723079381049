import { List, ListItem, ListItemText, makeStyles, Divider } from "@material-ui/core";
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const SafetyDrawer = (props) => {

    const classes = useStyles();

    return <List>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/hr/healthsafety/swp'} button>
            <ListItemText primary={'SWP'} />
        </ListItem>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/hr/healthsafety/safetypolicies'} button>
            <ListItemText primary={'Safety Policies'} />
        </ListItem>
        <Divider />
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/hr/healthsafety/all'} button>
            <ListItemText primary={'All'} />
        </ListItem>
    </List>
}