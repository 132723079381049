import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withRouter } from 'react-router'
import { firestore } from '../../../firebase/firebase'
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import HRCard from '../../components/HumanResources/HRCard';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex', 
        width:'100%', 
        padding: '32px', 
        justifyContent:'center',
        marginTop: '40px',
    },
}));

const HR = (props) =>  {

    const classes = useStyles();

    const [hrData, setHrData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});

    const docTypes = useRef([])

    const updateHrData = useCallback((e) => {
            setHrData(e)
        },
        [],
    );

    const saveHrData = () => {
        setSaving(true);
        firestore.collection('humanResources').doc(hrData.docId)
            .update(hrData)
            .then(() => {
                setSaveState(hrData);
                setSaving(false)
            }).catch((e) => setError(e));
    };

    const cancelSave = () => {
        setHrData(saveState);
    };

    useEffect(() => {

        let path = new URLSearchParams(props.location.search).get("docId");

        firestore.collection('humanResources').where('__name__', "==", path).get()
        .then(querySnapshot => {
            const hrResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                }
            })
            setHrData(hrResponse[0]);
            setSaveState(hrResponse[0]);
            //need better way to hold these values hard coded here (and ) is bad :(
            docTypes.current = hrResponse[0].hrType === 'general' 
                ? [{value: 'all', display: 'None'}, {value: 'policies', display: 'Policies'}, {value: 'accessibility', display: 'Accessibility'}] 
                : [{value: 'all', display: 'None'}, {value: 'swp', display: 'SWP'}, {value: 'safetypolicies', display: "Safety Policies"}]

            setLoading(false);
        })
        .catch(e => {
            setError("HR Document not found.");
            setLoading(false);
            console.log(e.message);
        })
    }, [props.location.search]);
        
    return (
        <div>
            {loading 
            ? 
            <div className={classes.content}>
                <Skeleton variant='rect' width={'40vw'} height={'45vh'}/>
            </div>
            : 
            <React.Fragment>
                <HRCard 
                    error={error}
                    hrData={hrData} 
                    saveState={saveState} 
                    cancelSave={cancelSave} 
                    saveHrData={saveHrData} 
                    updateHrData={updateHrData} 
                    saving={saving}
                    setSaveState={setSaveState}
                    docTypes={docTypes.current}
                />
            </React.Fragment>
            }
                
        </div>
    )
}

export default withRouter(HR);